import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import SingleFaq from './singleFaq'

const FAQStyles = styled.section`
  width: 100%;
  position: relative;
  padding: 3rem 0;
  z-index: 2;
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
    }
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.25rem;
    margin: 2rem auto;
    max-width: 1000px;
  }
`
const FAQWrapper = ({ data }) => (
  <FAQStyles>
    <h1>FAQS</h1>
    <div className="wrapper">
      {data.map((faq, i) => (
        <SingleFaq data={faq} key={i} />
      ))}
    </div>
  </FAQStyles>
)

export default FAQWrapper
