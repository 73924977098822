import React, { useState } from 'react'
import styled from 'styled-components'
import ArrowIcon from 'svgs/arrow-down.svg'

const SingleFaqStyles = styled.div`
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  .question {
    width: 100%;
    background-color: #363636;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    strong {
      text-transform: uppercase;
    }
    svg {
      transition: all 0.25s ease-in-out;
    }
    .active {
      transform: rotate(180deg);
    }
  }
  .answer {
    background-color: #212121;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    section {
      padding: 1rem;

      a {
        color: var(--main) !important;
        &:hover,
        &:active {
          color: var(--main);
        }
      }
    }
  }
  .active {
    max-height: 300rem;
    z-index: 10;
    opacity: 1;
    visibility: visible;
  }
`
const SingleFaq = ({ data }) => {
  const [open, setOpen] = useState(false)
  return (
    <SingleFaqStyles onClick={() => setOpen(!open)}>
      <div className="question">
        <strong>{data.question}</strong>
        <span>
          <ArrowIcon className={open ? 'active' : ''} />
        </span>
      </div>
      <div className={open ? 'answer active' : 'answer'}>
        <section dangerouslySetInnerHTML={{ __html: data.answer }}>
          {/* <p>{data.answer}</p> */}
        </section>
      </div>
    </SingleFaqStyles>
  )
}
export default SingleFaq
