import FAQWrapper from 'components/faqs/faqsWrapper'
import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40rem;
  z-index: 2;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 100;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 100;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media ${media.md} {
    height: 30rem;
  }
  @media ${media.xl} {
    height: 40rem;
  }
`
const meta = {
  title: 'FAQS | The Mousetrap',
}
const FAQPAGE = ({ data }) => {
  const mob = data.mobHeaderImg
  const tab = data.tabletHeaderImg
  const desk = data.deskHeaderImg
  const faq = data.faqs.nodes
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} tabletHeader={tab} desktopHeader={desk} />
      <MaxWidth $wooden>
        <FAQWrapper data={faq} />
      </MaxWidth>
    </Layout>
  )
}

export default FAQPAGE

export const FAQQuery = graphql`
  query {
    faqs: allFaqsJson {
      nodes {
        question
        answer
      }
    }
    mobHeaderImg: file(name: { eq: "7-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 575
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tabletHeaderImg: file(name: { eq: "7-tablet" }) {
      childImageSharp {
        gatsbyImageData(
          width: 991
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "7-desktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
